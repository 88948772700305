var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collections"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.ereditaculturali.views.collections.subtitle'))+" ")]},proxy:true},{key:"main",fn:function(){return [_c('CrudTable',{attrs:{"title":_vm.$t('plugins.ereditaculturali.views.collections.title'),"model":"collections","model-name-field":"code","check-owner":false,"order-asc":false,"check-roles":['archive_manager', 'card_manager'],"columns":[
          {name: _vm.$t('plugins.ereditaculturali.views.collections.fields.code'), field: 'code'},
          {name: _vm.$t('plugins.ereditaculturali.views.collections.fields.name'), field: 'name'},
          {name: _vm.$t('plugins.ereditaculturali.views.collections.fields.image'), field: 'code', view: 'thumb'},
          {name: _vm.$t('plugins.ereditaculturali.views.collections.fields.fcards_count'), field: 'fcards_count'},
          {field: 'actions'},
        ]}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }