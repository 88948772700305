var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"placenames"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.ereditaculturali.views.placenames.subtitle'))+" ")]},proxy:true},{key:"main",fn:function(){return [_c('CrudTable',{attrs:{"title":_vm.$t('plugins.ereditaculturali.views.placenames.title'),"model":"placenames","model-name-field":"code","check-owner":false,"order-asc":false,"check-roles":['archive_manager', 'card_manager'],"columns":[
          {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.code'), field: 'code', related: 'card'},
          {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.name'), parent: 'card', field: 'name'},
          {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.card'), related: 'card', parent: 'parents', field: 'name', view: 'multiple'},
          {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.archive'), parent: 'archive', field: 'name', related: 'card'},
          {name: _vm.$t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', view: 'check', icon: 'eye'},
          {name: _vm.$t('views.cards.fields.is_published'), field: 'is_published', related: 'card', view: 'check', icon: 'globe'},
          {field: 'actions'},
        ],"cards-importer":true,"cards-importer-archive-text":_vm.$t('plugins.ereditaculturali.views.placenames.importer_archive_text'),"cards-import-model":"placename-import-model.xlsx","cards-file-types-allowed":[
          {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
          {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
          {name: 'CSV', type: 'text/csv'}
        ]}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }