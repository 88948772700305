<template>
  <div class="f_cards">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.ereditaculturali.views.f_cards.subtitle') }}
      </template>
      <template v-slot:main>
        <CrudTable 
          :title="$t('plugins.ereditaculturali.views.f_cards.title')" 
          model="f_cards"
          model-name-field="code"
          :check-owner="true"
          :order-asc="false"
          :check-roles="['archive_manager', 'card_manager']"
          :columns="[
            {name: $t('plugins.ereditaculturali.views.f_cards.fields.code'), field: 'code', related: 'card'},
            {name: $t('plugins.ereditaculturali.views.f_cards.fields.image'), field: 'code', related: 'card', view: 'thumb'},
            {name: $t('plugins.ereditaculturali.views.f_cards.fields.archive'), parent: 'archive', field: 'name', related: 'card'},
            {name: $t('plugins.ereditaculturali.views.f_cards.fields.collection'), parent: 'collection', field: 'name', model: 'collections'},
            {name: $t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', view: 'check', icon: 'eye'},
            {name: $t('views.cards.fields.is_published'), field: 'is_published', related: 'card', view: 'check', icon: 'globe'},
            {field: 'actions'},
          ]"
          :cards-importer="true"
          :cards-importer-archive-text="$t('plugins.ereditaculturali.views.f_cards.importer_archive_text')"
          cards-import-model="f-card-import-model.xlsx"
          :cards-file-types-allowed="[
            {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
            {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            {name: 'CSV', type: 'text/csv'}
          ]"
          :search-filters="[
            {field: 'code',     type: 'text',   label: $t('plugins.ereditaculturali.views.f_cards.fields.code')},
            {field: 'archive_id', type: 'select', model: 'archives', search_field: 'name', label: $t('plugins.ereditaculturali.views.f_cards.fields.archive'), placeholder: $t('views.archives.select')},
            {field: 'collection_id', type: 'select', model: 'collections', search_field: 'name', label: $t('plugins.ereditaculturali.views.f_cards.fields.collection'), labels: ['code', 'name'], placeholder: $t('plugins.ereditaculturali.views.collections.select')}
          ]"
          :media-importer="true"
          :media-importer-text="$t('plugins.ereditaculturali.views.f_cards.importer_media_text')"
          :media-file-types-allowed="[
            {name: 'PNG', type: 'image/png'},
            {name: 'JPG', type: 'image/jpeg'},
            {name: 'TIFF', type: 'image/tiff'}
          ]" 
        />
      </template>
    </BaseLayout>
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudTable from '@/components/CrudTable'

export default {
  name: 'FCards',
  components: {
    BaseLayout, CrudTable
  }
}
</script>