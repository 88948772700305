<template>
  <div class="collections">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.ereditaculturali.views.collections.subtitle') }}
      </template>
      <template v-slot:main>
        <CrudTable 
          :title="$t('plugins.ereditaculturali.views.collections.title')" 
          model="collections"
          model-name-field="code"
          :check-owner="false"
          :order-asc="false"
          :check-roles="['archive_manager', 'card_manager']"
          :columns="[
            {name: $t('plugins.ereditaculturali.views.collections.fields.code'), field: 'code'},
            {name: $t('plugins.ereditaculturali.views.collections.fields.name'), field: 'name'},
            {name: $t('plugins.ereditaculturali.views.collections.fields.image'), field: 'code', view: 'thumb'},
            {name: $t('plugins.ereditaculturali.views.collections.fields.fcards_count'), field: 'fcards_count'},
            {field: 'actions'},
          ]"
        />
      </template>
    </BaseLayout>
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudTable from '@/components/CrudTable'

export default {
  name: 'Collections',
  components: {
    BaseLayout, CrudTable
  }
}
</script>